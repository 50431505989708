<template>
  <div class="page">
    <img :src="icon" class="img">
    <p class="text">{{ title }}</p>
  </div>
</template>

<script>
import noneIcon from '@/assets/icon_none.png'
export default {
  props: {
    title: {
      type: String,
      default: '暂无数据哦~'
    },
    icon: {
      type: String,
      default: noneIcon
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding-top: 40px;
  text-align: center;
  .img {
    width: 164px;
    height: 180px;
  }
  .text {
    padding-top: 20px;
    font-size: 14px;
    color: #979899;
  }
}
</style>
