
import { getOssUrl } from '@/utils';

import user from '@/store/modules/user';
<template>
  <div class="user-coupon">
    <van-tabs v-model="active" color="#FF7226" title-active-color="#FF7226" background="#fff" @change="tabChange">
      <van-tab name="1" title="全部" />
      <van-tab name="2" title="我的邀约" />
      <van-tab name="3" title="好友邀约" />
    </van-tabs>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length > 0 ? '没有更多了' : ''"
        @load="getList"
      >
        <div v-if="list.length > 0 || firstLoading" class="list">
          <div class="box">
            <div v-for="item in list" :key="item.id" class="item">
              <div class="pic" :style="item.user.avatarUrl?'background-image:url('+item.user.avatarUrl+')':''" />
              <div class="info">
                <div class="name">{{ item.user.realName }}</div>
                <div class="phone">{{ item.user.mobile }}</div>
                <div class="date">{{ item.createdTime | parseTime }}</div>
              </div>
              <div class="status">
                <div v-if="item.loanStatus==1" class="title begin">未贷款</div>
                <div v-else-if="item.loanStatus==2" class="title ing">贷款中</div>
                <div v-else-if="item.loanStatus==3" class="title past">贷款成功</div>
                <div v-else class="title fail">贷款失败</div>
                <div v-if="item.sourceUser && item.sourceUser.realName" class="des">来源：{{ item.sourceUser.realName }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="emprty">
          <DefaultPage />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { listInviteUsers } from '@/api/user'
import DefaultPage from '@/components/DefaultPage'
import { parseTime } from '@/utils/index'
import { getOssUrl } from '@/utils/oss'
export default {
  components: { DefaultPage },
  filters: {
    parseTime
  },
  data() {
    return {
      active: 1,
      list: [],
      params: { limit: 10, page: 1, queryType: 1, createTimeSort: 2 },
      count: 0,
      loading: false,
      finished: false,
      refreshing: false,
      firstLoading: true
    }
  },
  methods: {
    tabChange() {
      this.params.queryType = this.active
      this.list = []
      this.firstLoading = true
      this.onRefresh()
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.params.page = 1
      this.getList()
    },
    async getList() {
      const res = await listInviteUsers(this.params)
      if (this.refreshing) {
        this.list = []
        this.page = 1
        this.refreshing = false
      }
      const list = res.datas && res.datas.data || []
      for (let i = 0; i < list.length; i++) {
        const item = { ...list[i] }
        if (item.user && item.user.avatarUrl) {
          item.user.avatarUrl = await getOssUrl(item.user.avatarUrl)
        }
        this.list.push(item)
      }
      this.loading = false
      const count = res.datas && res.datas.count
      this.count = count
      if (this.list.length >= count * 1) {
        this.finished = true
      }
      this.params.page += 1
      this.firstLoading = false
    }
  }
}
</script>
  <style lang="scss" scoped>
  .user-coupon::v-deep {
    .van-tabs__wrap {
      box-shadow: 0px 1px 1px rgba(0,0,0,0.06);
    }
    .list {
      padding-top: 10px;
      .box {
        padding: 0 10px 10px 10px;
      }
      .item{
            display: flex;
            padding:.4rem 0;
            margin-top:.266667rem;
            border-bottom: 1px solid #F3F3F5;
            .pic{
                width: .986667rem;
                height: .986667rem;
                border-radius: .986667rem;
                background-size: 100%;
                .img{
                    width: .986667rem;
                    height: .986667rem;
                }
            }
            .info{
                flex: 1;
                padding-left: .2rem;
                .name{
                    font-size: .426667rem;
                    color:#323233;
                }
                .phone{
                    padding-top: .266667rem;
                    font-size: .373333rem;
                    color:#979799;
                }
                .date{
                    padding-top: .266667rem;
                    font-size: .373333rem;
                    color:#979799;
                }
            }
            .status{
                text-align: right;
                .title{
                    font-size: .426667rem;
                }
                .past{
                    color:#18C47C;
                }
                .fail {
                    color: #F86767;
                }
                .begin {
                    color: #FF7700;
                }
                .ing {
                    color: #2697FF;
                }
                .des{
                    padding-top:.266667rem;
                    font-size: .373333rem;
                    color:#CACACC;
                }
            }
        }
    }
  }
  </style>

